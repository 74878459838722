import React from 'react';
import { getUserPlanDataUtil } from "../utils/utilFn";



const Navigation = ({ activeNavItem, setActiveNavItem, openModal }) => {
    const handleNavigationClick = (navItem) => {
        setActiveNavItem(navItem); // Call the setActiveNavItem function passed from parent with the selected navigation item
        getUserPlanDataUtil();
      };

    const handleFeedbackClick = (action) => {
    // Call the callback to open the modal in App.js
    openModal(null,"", "feedback");
    };    
  return (
    <div class="leftCol">
        <div class="main_navigation">
            <ul class="nav">
                <li className={activeNavItem === 'Decks' ? 'active' : ''}>
                    <a href="javascript:void(0);" onClick={() => handleNavigationClick('Decks')}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M9 17L4 22M15 17L20 22M12 2V4M12 22V17M5.2 17H18.8C19.9201 17 20.4802 17 20.908 16.782C21.2843 16.5903 21.5903 16.2843 21.782 15.908C22 15.4802 22 14.9201 22 13.8V7.2C22 6.07989 22 5.51984 21.782 5.09202C21.5903 4.71569 21.2843 4.40973 20.908 4.21799C20.4802 4 19.9201 4 18.8 4H5.2C4.07989 4 3.51984 4 3.09202 4.21799C2.71569 4.40973 2.40973 4.71569 2.21799 5.09202C2 5.51984 2 6.07989 2 7.2V13.8C2 14.9201 2 15.4802 2.21799 15.908C2.40973 16.2843 2.71569 16.5903 3.09202 16.782C3.51984 17 4.0799 17 5.2 17Z" stroke="#D9D9D9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span>Decks</span>
                    </a>
                </li>
                <li className={activeNavItem === 'Presenters' ? 'active' : ''}>
                    <a href="javascript:void(0);" onClick={() => handleNavigationClick('Presenters')}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M22 21V19C22 17.1362 20.7252 15.5701 19 15.126M15.5 3.29076C16.9659 3.88415 18 5.32131 18 7C18 8.67869 16.9659 10.1159 15.5 10.7092M17 21C17 19.1362 17 18.2044 16.6955 17.4693C16.2895 16.4892 15.5108 15.7105 14.5307 15.3045C13.7956 15 12.8638 15 11 15H8C6.13623 15 5.20435 15 4.46927 15.3045C3.48915 15.7105 2.71046 16.4892 2.30448 17.4693C2 18.2044 2 19.1362 2 21M13.5 7C13.5 9.20914 11.7091 11 9.5 11C7.29086 11 5.5 9.20914 5.5 7C5.5 4.79086 7.29086 3 9.5 3C11.7091 3 13.5 4.79086 13.5 7Z" stroke="#A3A3A3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span>Presenters</span>
                    </a>
                </li>
                <li class={activeNavItem === 'Analytics' ? 'active' : ''} style={{ display: activeNavItem === 'Analytics' ? 'block' : 'none' }}>
                    <a href="javascript:void(0);" >
                    <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 5H2.6C2.03995 5 1.75992 5 1.54601 5.10899C1.35785 5.20487 1.20487 5.35785 1.10899 5.54601C1 5.75992 1 6.03995 1 6.6V17.4C1 17.9601 1 18.2401 1.10899 18.454C1.20487 18.6422 1.35785 18.7951 1.54601 18.891C1.75992 19 2.03995 19 2.6 19H7M7 19H13M7 19L7 2.6C7 2.03995 7 1.75992 7.10899 1.54601C7.20487 1.35785 7.35785 1.20487 7.54601 1.10899C7.75992 1 8.03995 1 8.6 1L11.4 1C11.9601 1 12.2401 1 12.454 1.10899C12.6422 1.20487 12.7951 1.35785 12.891 1.54601C13 1.75992 13 2.03995 13 2.6V19M13 9H17.4C17.9601 9 18.2401 9 18.454 9.10899C18.6422 9.20487 18.7951 9.35785 18.891 9.54601C19 9.75992 19 10.0399 19 10.6V17.4C19 17.9601 19 18.2401 18.891 18.454C18.7951 18.6422 18.6422 18.7951 18.454 18.891C18.2401 19 17.9601 19 17.4 19H13" stroke="#D9D9D9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
               </svg>
                        <span>Analytics</span>
                    </a>
                </li>
            </ul>
            <ul class="footerNav">
            <li class={activeNavItem === 'Payment' ? 'active' : ''}>
                    <a href="javascript:void(0);" onClick={() => handleNavigationClick('Payment')}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M3 8L15 8M15 8C15 9.65686 16.3431 11 18 11C19.6569 11 21 9.65685 21 8C21 6.34315 19.6569 5 18 5C16.3431 5 15 6.34315 15 8ZM9 16L21 16M9 16C9 17.6569 7.65685 19 6 19C4.34315 19 3 17.6569 3 16C3 14.3431 4.34315 13 6 13C7.65685 13 9 14.3431 9 16Z" stroke="#A3A3A3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span>Payment</span>
                    </a>
                </li>
            <li class={activeNavItem === 'feedback' ? 'active' : ''}>
                    <a href="javascript:void(0);"  onClick={() => handleFeedbackClick('feedback')}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M3 8L15 8M15 8C15 9.65686 16.3431 11 18 11C19.6569 11 21 9.65685 21 8C21 6.34315 19.6569 5 18 5C16.3431 5 15 6.34315 15 8ZM9 16L21 16M9 16C9 17.6569 7.65685 19 6 19C4.34315 19 3 17.6569 3 16C3 14.3431 4.34315 13 6 13C7.65685 13 9 14.3431 9 16Z" stroke="#A3A3A3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span>Feedback</span>
                    </a>
                </li>
                <li class={activeNavItem === 'Account' ? 'active' : ''}>
                    <a href="javascript:void(0);" onClick={() => handleNavigationClick('Account')}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M3 8L15 8M15 8C15 9.65686 16.3431 11 18 11C19.6569 11 21 9.65685 21 8C21 6.34315 19.6569 5 18 5C16.3431 5 15 6.34315 15 8ZM9 16L21 16M9 16C9 17.6569 7.65685 19 6 19C4.34315 19 3 17.6569 3 16C3 14.3431 4.34315 13 6 13C7.65685 13 9 14.3431 9 16Z" stroke="#A3A3A3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span>Account Settings</span>
                    </a>
                </li>

            </ul>
    </div>
</div>     
  );
};

export default Navigation;
