import React, { useState, useEffect } from 'react';
import { apiUrls } from "../utils/apiUrls";
import { callAPI } from "../utils/apiUtils";

const PaymentSetting = ({setPlanforPayment}) => {

  const [plans, setPlans] = useState([]);
  const [userPlan, setUserPlan] = useState(false);

    const handleUpgradeClick = async (planId) => {
      setPlanforPayment(planId)
    };
    useEffect(() => {
      const fetchData = async () => {
        try {
          const userId=localStorage.getItem("userId")
          const response = await callAPI(apiUrls.GETPAYMENTPLANS, {}, "GET");

          console.log(response);
          setPlans(response.data.plans); 
          const userResponse = await callAPI(apiUrls.GETUSERPLAN, {userId:userId}, "GET");
          console.log(userResponse.data.plan);
          if(userResponse.data.plan) {
            setUserPlan(userResponse.data.plan)
          }

        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData(); // Call the fetchData function when the component mounts
    }, []);     
 
    return (
        <div class="dashContent deckDashboard">
          <h1>Payment Settings</h1>
          <div class="dForm deckListing mt-24">
            <div class="rowList flexWrap">
          
                
                
                
                  {plans.map((plan, index) => {
                  const description = JSON.parse( plan.description);
                  return (
                    <div class="itemWrap dbBox " style={{width:"30%", margin:"10px", padding:"0px", backgroundColor: userPlan && userPlan.planId === plan.pid ? "rgb(217, 217, 217)" : "" , color: userPlan && userPlan.planId === plan.pid ? "black" : "" }}>
            <div class="item row jcSpacebetween gap24">
                       
                         <div class="col mrAuto main_navigation" style={{borderRight:"none"}}>
                          <span><h3>{plan.title}</h3></span>
                           <span> <h1>{description.priceLabel}</h1></span>
                           <span> {description.summary}</span>
                           <span style={{borderBottom:"2px solid #424242"}}>&nbsp;</span>
                           <ul class="nav" style={{marginTop:"20px"}}>
                           {description.features.map((feature, featureIndex) => (
                           
                           <li><span><svg fill= {userPlan && userPlan.planId === plan.pid ? "#000000" : "#ffffff"} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" 
                           width="20px" height="20px" viewBox="0 0 305.002 305.002"
                           >
                       <g>
                          <g>
                             <path d="M152.502,0.001C68.412,0.001,0,68.412,0,152.501s68.412,152.5,152.502,152.5c84.089,0,152.5-68.411,152.5-152.5
                                S236.591,0.001,152.502,0.001z M152.502,280.001C82.197,280.001,25,222.806,25,152.501c0-70.304,57.197-127.5,127.502-127.5
                                c70.304,0,127.5,57.196,127.5,127.5C280.002,222.806,222.806,280.001,152.502,280.001z"/>
                             <path d="M218.473,93.97l-90.546,90.547l-41.398-41.398c-4.882-4.881-12.796-4.881-17.678,0c-4.881,4.882-4.881,12.796,0,17.678
                                l50.237,50.237c2.441,2.44,5.64,3.661,8.839,3.661c3.199,0,6.398-1.221,8.839-3.661l99.385-99.385
                                c4.881-4.882,4.881-12.796,0-17.678C231.269,89.089,223.354,89.089,218.473,93.97z"/>
                          </g>
                       </g>
                       </svg></span><span>{feature}</span>
                       </li>
                          ))}
                          </ul>



                         </div>
                         <div class="col" style={{textAlign:"center", width:"100%"}}>
                         {/* <a href="javascript:void(0);" className="btn PrimaryOutline"  onClick={handleUpgradeClick} >Upgrade Plan</a> */}
                         {userPlan.planId !== plan.pid ? (
                         <button className="btn PrimaryOutline" style={{width:"80%", background:"rgb(217, 217, 217)", color:"black"}}  onClick={() => {handleUpgradeClick(plan.pid); }}>
                            Upgrade Plan
                        </button>
                        ) : (
                          <button className="btn PrimaryOutline" style={{width:"80%", background:"#000000", color:"rgb(217, 217, 217)"}}  >
                            Current Plan
                        </button>
                        )}

                         </div>
                      </div>
        
                   </div>
                  );
})}
                   
                
        
             </div>
             
          </div>
      </div>

    );
};

export default PaymentSetting;