import { apiUrls } from "../utils/apiUrls";
import { callAPI } from "../utils/apiUtils";
import { constants } from "../utils/constants";

export const isValidEmail = (email) => {
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    return emailRegex.test(email);
  };

  export const getUserPlanDataUtil = async() => {
    const userId=localStorage.getItem("userId")
    const response = await callAPI(apiUrls.GETUSERPLANDATA, {userId:userId}, "GET");
    console.log(response.data);
    if(response.data) {
        localStorage.setItem("upid", JSON.stringify(response.data))
    } else {
        localStorage.setItem("upid", JSON.stringify({planExpired:true}))
    }
  };

  export const checkPlanDataUtil = (eventType) => {
    // Retrieve the stored data
    const upid = JSON.parse(localStorage.getItem("upid"));
    if (upid && (upid.planExpired === true || upid.planExpired === "true")) {
        return false;
      }
    // Access userActualData and userRestData
    const userActualData = upid.userActualData || {};
    const userRestData = upid.userRestData || {};

    // Get the restriction value for the specific eventType
    const restValue = userRestData[eventType] || 0;

    // Calculate the actual value for comparison
    let actualValue;

    if (eventType === constants.T_DU_TOTAL) {
        return true;
        // Special case: Sum the actual values of 1000 and 1015
        actualValue = (userActualData[constants.T_DU_PPT] || 0) + (userActualData[constants.T_DU_PDF] || 0);
    } else {
        // Standard case: Use the actual value of the given eventType
        actualValue = userActualData[eventType] || 0;
    }

    // Perform comparison
    if (restValue > actualValue) {
        console.log(`Event Type ${eventType}: Restriction allows usage.`);
        return true; // Restriction allows usage
    } else {
        console.log(`Event Type ${eventType}: Restriction exceeded or no usage allowed.`);
        return false; // Restriction exceeded or usage not allowed
    }
};
