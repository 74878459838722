import React, { useState, useEffect } from 'react';
import { apiUrls } from "../utils/apiUrls";
import { callAPI } from "../utils/apiUtils";
import './StripeButton.css'; 
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

// Initialize Stripe
const stripePromise = loadStripe('pk_test_51P82osHjl5nedYUgzDXb1n783GwQswARj2R7fRNe5lvhIc8Q5kTFgbqY3TCUput7Lja4agAeRPxEzd05y7VTdQu200WOLlj0mf'); // Replace with your actual Stripe publishable key

const CheckoutForm = ({ clientSecret, planId }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState('');
  const [isPaymentSuccessful, setPaymentSuccessful] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: 'white',
        backgroundColor: 'black',
        '::placeholder': {
          color: 'white',
        },
        border: '1px solid white',
        width:'500px'
      },
      invalid: {
        color: '#fa755a',
      },
    },
  };
  

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setIsSubmitting(true);
    setErrorMessage('');

    const cardElement = elements.getElement(CardElement);

    const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
      },
    });

    if (error) {
      setErrorMessage(error.message);
    } else {
      setPaymentSuccessful(true);
      console.log('Payment succeeded:', paymentIntent);
      // Handle post-payment success (e.g., update user plan)
      const response = await callAPI(apiUrls.UPDATEPAYMETINTENT, {}, "POST",{ paymentIntentId: paymentIntent.id, planId:planId });
    }
    setIsSubmitting(false);
  };

  return (
    !isPaymentSuccessful ? (
    <form onSubmit={handleSubmit}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      <CardElement  options={CARD_ELEMENT_OPTIONS} />
      {/* <button type="submit" disabled={!stripe}> */}
      <button type="submit" disabled={!stripe || isSubmitting || isPaymentSuccessful}>
      {isPaymentSuccessful ? 'Payment Successful' : 'Pay'}
      </button>
      </div>
      {errorMessage && <div>{errorMessage}</div>}
      {isPaymentSuccessful && <div>Thank you for your payment!</div>}
    </form>
     ):(
      <div>Thank you for your payment!</div>
    )
  );
};

const PaymentForm = ({ selectedPaymentPlan}) => {

    const [clientSecret, setClientSecret] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const handleFirstNameChange = async(event) => {
      const value = event.target.value;
      setFirstName(value)
      
  }
  const handleLastNameChange = async(event) => {
      const value = event.target.value;
      setLastName(value)
      
  }
    
    const handleUpgradeClick = async () => {
      const amount = 50; // Example amount in cents ($10)
      console.log("jjjjj")
      try {
        const formData = new FormData();
         formData.append("amount", amount);


         const headers={
          "Content-Type": "application/json",
          }
         
        const response = await callAPI(apiUrls.CREATEPAYMETINTENT, {}, 'POST', formData, {}, headers);
        console.log("response.clientSecret", response.data.clientSecret)
        
        setClientSecret(response.data.clientSecret);
      } catch (error) {
        console.error('Error creating payment intent:', error);
      }
    };
    useEffect(() => {
      handleUpgradeClick();
    }, []);
 
    return (
        <div class="dashContent">
          <h1>Upgrade Plan</h1>
          <div class="dForm mt-32">
            <div class="wrapper">
           
                <section class="infBox viewPlan">
                  <h3>Payment Detal
                     <span>Review your selected plan {selectedPaymentPlan ? `- ${selectedPaymentPlan.title}` : ""} {selectedPaymentPlan ? `- ${ JSON.parse(selectedPaymentPlan.description).price}` : ""}</span>
                  </h3>
                  <script async src="https://js.stripe.com/v3/buy-button.js"></script>
                   <div class="dbBox mt-12">
                   <div class="row gap24">
                        <div class="col flex flexColum w100">
                           <label class="mb-8">Name on card</label>
                           <input type="text" value={firstName}  name="" placeholder="Name on card" class="w100" onChange={handleFirstNameChange}/>
                        </div>
                     
                     </div>
                      <div class="row" style={{width:"100%"}}>
                   {clientSecret && (
            <Elements stripe={stripePromise}>
              <CheckoutForm clientSecret={clientSecret} planId={selectedPaymentPlan.pid}/>
            </Elements>
          )} </div>
                   </div>
         
                   
                </section>

                <section class="infBox viewPlan">
                  <h3>Billing Detail
                  </h3>
                  <div class="dbBox mt-12">
                     <div class="row gap24">
                        <div class="col flex flexColum w100">
                           <label class="mb-8">Street Address</label>
                           <input type="text" value="" name="" placeholder="Street Address" class="w100" />
                        </div>
                      </div>
                      <div class="row gap24">
                          <div class="col flex flexColum w100">
                            <label class="mb-8">Street Address 2 (Optional)</label>
                            <input type="text" value="" name="" placeholder="Street Address 2" class="w100" />
                          </div>
                      </div>
                      <div class="row gap24">
                        <div class="col flex flexColum w100">
                           <label class="mb-8">City</label>
                           <input type="text" value=""  name="" placeholder="City" class="w100" />
                        </div>
                        <div class="col flex flexColum w100">
                           <label class="mb-8">State</label>
                           <input type="text" value="" name="" placeholder="State" class="w100" />
                        </div>
                     </div>
                     
                     <div class="row gap24">
                        <div class="col flex flexColum w100">
                           <label class="mb-8">Country</label>
                           <input type="text" value="" name="" placeholder="Country" class="w100" />
                        </div>
                      </div>
                    </div>
                </section>
        
        
             </div>
          </div>
      </div>

    );
};

export default PaymentForm;