// import axios from 'axios';
import axios, * as others from 'axios';
import Qs from 'qs';
import {defaultConfig} from '../config';

const serializerConfig = {
    arrayFormat: 'brackets',
    encode: false,
};


function callAPI(path, params, method, data = null, options = {}, headersObj = {}){
    const API_ROOT = defaultConfig.baseAPIUrl;
    const url = API_ROOT + path;
    const headers = {
        Accept: 'application/json',
        
        ...headersObj,
    };

    const userId = localStorage.getItem('userId');
    // If userId exists, include it in the query parameters
    if (userId !== undefined && userId !== null && userId !== '') {
        params = { ...params, globalUserId: userId };
    }
    // Add a random parameter to prevent caching
    params = { ...params, _cacheBuster: new Date().getTime() }; // or use Math.random()

    const sessionId = localStorage.getItem('sessionId');
    // If userId exists, include it in the query parameters
    if (sessionId !== undefined && sessionId !== null && sessionId !== '') {
        params = { ...params, sessionId: sessionId };
    }

    return axios({
        method,
        url,
        params,
        paramsSerializer: (paramObject) => Qs.stringify(paramObject, serializerConfig),
        data,
        headers,
        ...options,
    });
}



export {callAPI};